import { dataEndpoint } from "./data";

async function retrieveAccounts(setAccountsFunc, token, selectedProject, oauth_id_token) {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Bearer " + token + " Project " + selectedProject, "Identity": oauth_id_token },
  }
  const getAccounts = `${dataEndpoint}/api/project/` + selectedProject + `/accounts`;
  console.log("retrieveAccounts", requestOptions, getAccounts)
  const resp = await fetch(getAccounts, requestOptions)
  if(resp.status === 200) {
    const data = await resp.json()
    setAccountsFunc(data)
  }
}

async function retrieveMembers(setMemberFunc, token, selectedProject, oauth_id_token) {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Bearer " + token + " Project " + selectedProject, "Identity": oauth_id_token },
  }
  const getMembers = `${dataEndpoint}/api/project/` + selectedProject + `/users`;
  console.log("retrieveMembers", requestOptions, getMembers)
  const resp = await fetch(getMembers, requestOptions)
  if(resp.status === 200) {
    const data = await resp.json()
    setMemberFunc(data)
  }
}

async function removeUser(user_email, token, selectedProject, oauth_id_token) {
  const requestOptions = {
    method: 'DELETE',
    headers: { "Authorization": "Bearer " + token + " Project " + selectedProject, "Identity": oauth_id_token },
    body: JSON.stringify({
      emails: [user_email]
    })
  }
  const deleteUsers = `${dataEndpoint}/api/project/` + selectedProject + `/users`;
  console.log("removeUser", requestOptions, deleteUsers)
  const resp = await fetch(deleteUsers, requestOptions)
  console.log(resp.status)
  return resp
}

async function retrieveUserOrgs(token, id_token, setFunc) {
  console.log("retrieveUserOrgs params", token, id_token, setFunc);
  if (token != null && id_token != null) {
    const requestOptions = {
      method: 'GET',
      headers: { "Authorization": "Bearer " + token, "Identity": id_token.__raw },
    }
    const getOrgs = `${dataEndpoint}/api/user/projects`;
    console.log("retrieveUserOrgs", requestOptions, getOrgs)
    const resp = await fetch(getOrgs, requestOptions)
    console.log("retrieveUserOrgs resp", resp)
    if(resp.status === 200) {
      const data = await resp.json()
      setFunc(data)
    }
  }
}

async function checkUserAccountConfigured(token, id_token, account_id, project_id) {
  if(token != null && id_token != null) {
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization": "Bearer " + token, "Identity": id_token},
    }
    const accConfigPath = `${dataEndpoint}/api/project/${project_id}/account/${account_id}/configure`
    console.log("checkUserAccountConfigured", accConfigPath, requestOptions)
    const res = await fetch(accConfigPath, requestOptions)
    return res
  }
  return null
}

async function retrieveUserScopes(token, id_token, projectId, setFunc) {
  if (token != null && id_token != null && projectId != null) {
    const requestOptions = {
      method: 'GET',
      headers: { "Authorization": "Bearer " + token + " Project " + projectId, "Identity": id_token.__raw },
    }
    const getOrgs = `${dataEndpoint}/api/user/project/${projectId}/scopes`;
    console.log("retrieveUserScopes", requestOptions, getOrgs)
    const resp = await fetch(getOrgs, requestOptions)
    console.log("retrieveUserScopes resp", resp)
    if(resp.status === 200) {
      const data = await resp.json()
      console.log("retrieveUserScopes data", data)
      setFunc(data)
    }
  }
}

async function addAccountsToOrg(accountids, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'POST',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify({
      accounts: accountids
    })
  }
  const addAccounts = `${dataEndpoint}/api/project/${projectId}/accounts`;
  console.log("addAccountsToOrg", requestOptions, addAccounts, accountids)
  return fetch(addAccounts, requestOptions)
}

async function getProjectDefaults(projectId, auth_token, id_token) {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token }
  }
  const getDefaults = `${dataEndpoint}/api/project/${projectId}/defaults`;
  console.log("getProjectDefaults", requestOptions, getDefaults)
  const resp = await fetch(getDefaults, requestOptions)
  console.log(resp.status)
  if(resp.status === 200) {
    const data = await resp.json()
    console.log(data)
    return data
  } else {
    return resp.status
  }
}

async function addMembersToOrg(emails, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'POST',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify({
      user_emails: emails
    })
  }
  const addAccounts = `${dataEndpoint}/api/project/${projectId}/users`;
  console.log("addMembersToOrg", requestOptions, addAccounts, emails)
  const resp = await fetch(addAccounts, requestOptions)
  console.log(resp.status)
  return resp.status
}

async function deleteAccountsFromOrg(accountids, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'DELETE',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify({
      accounts: accountids
    })
  }
  const addAccounts = `${dataEndpoint}/api/project/${projectId}/accounts`;
  console.log("addAccountsToOrg", requestOptions, addAccounts, accountids)
  const resp = await fetch(addAccounts, requestOptions)
  console.log(resp.status)
  return resp.status
}

async function updateUserForOrg(userData, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'PUT',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify(userData)
  }
  const updateUser = `${dataEndpoint}/api/project/${projectId}/user`;
  console.log("updateAccountForOrg", requestOptions, updateUser, userData)
  const resp = await fetch(updateUser, requestOptions)
  console.log(resp.status)
  return resp.status
}

async function updateAccountForOrg(accountData, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'PUT',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify(accountData)
  }
  const updateAccount = `${dataEndpoint}/api/project/${projectId}/account/${accountData.accountid}`;
  console.log("updateAccountForOrg", requestOptions, updateAccount, accountData)
  const resp = await fetch(updateAccount, requestOptions)
  console.log(resp.status)
  return resp.status
}

async function updateOrgDefaults(orgData, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'PUT',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify(orgData)
  }
  const updateAccount = `${dataEndpoint}/api/project/${projectId}/defaults`;
  return fetch(updateAccount, requestOptions)
}

async function createSchedule(scheduleData, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'POST',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify(scheduleData)
  }
  const createSchedule = `${dataEndpoint}/api/project/${projectId}/schedules`;
  console.log("createSchedule", requestOptions, createSchedule, scheduleData)
  const resp = await fetch(createSchedule, requestOptions)
  console.log(resp.status)
  return resp
}

async function updateSchedule(scheduleData, auth_token, projectId, id_token) {
  const requestOptions = {
    method: 'PUT',
    headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    body: JSON.stringify(scheduleData)
  }
  const updateSchedule = `${dataEndpoint}/api/project/${projectId}/schedule/${scheduleData.id}`;
  console.log("updateSchedule", requestOptions, updateSchedule, scheduleData)
  const resp = await fetch(updateSchedule, requestOptions)
  console.log(resp.status)
  return resp
}

async function retrieveOrgSchedules(auth_token, projectId, id_token) {
  if (auth_token != null && id_token != null && projectId != null) {
    const requestOptions = {
      method: 'GET',
      headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    }
    const getSchedules = `${dataEndpoint}/api/project/${projectId}/schedules`;
    console.log("retrieveOrgSchedules", requestOptions, getSchedules)
    const resp = await fetch(getSchedules, requestOptions)
    console.log("retrieveOrgSchedules resp", resp)
    return resp
  }
}

async function deleteOrgSchedule(auth_token, project_id, id_token, schedule_id) {
  if (auth_token != null && id_token != null && project_id != null) {
    const requestOptions = {
      method: 'DELETE',
      headers: { "Authorization": "Bearer " + auth_token + " Project " + project_id, "Identity": id_token },
    }
    const getSchedules = `${dataEndpoint}/api/project/${project_id}/schedule/${schedule_id}`;
    console.log("deleteOrgSchedule", requestOptions, getSchedules)
    return fetch(getSchedules, requestOptions)
  }
}

export { checkUserAccountConfigured, deleteOrgSchedule, retrieveOrgSchedules, retrieveMembers, retrieveAccounts, retrieveUserOrgs, retrieveUserScopes, getProjectDefaults, removeUser, addAccountsToOrg, deleteAccountsFromOrg, updateAccountForOrg, updateUserForOrg, addMembersToOrg, updateSchedule, createSchedule, updateOrgDefaults }